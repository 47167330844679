


























































































































































































































import { Component, Vue } from 'vue-property-decorator'
import OrderPane from '@/components/order/order-pane.vue'
import DatePicker from '@/components/date-picker.vue'
import { apiOrderLists, apiOtherLists, apiDeliverymanAll } from '@/api/order/order'
import {
    apiCommunityList, // 小区列表
} from '@/api/delivery'
import ExportData from '@/components/export-data/index.vue'
import { RequestPaging } from '@/utils/util'
import { OrderType } from '@/utils/type'
import { timeFormat, funDate } from '@/utils/util'
@Component({
    components: {
        OrderPane,
        DatePicker,
        ExportData
    }
})
export default class Order extends Vue {
    // S Data
    activeName: any = 'all_count' //全部;

    apiOrderLists = apiOrderLists

    deliverymanList = [] // 配送员列表
    communityList = [] // 小区列表

    tabs = [
        {
            label: '全部',
            name: OrderType[0]
        },
        {
            label: '待付款',
            name: OrderType[1]
        },
        {
            label: '待配送',
            name: OrderType[2]
        },
        {
            label: '配送中',
            name: OrderType[3]
        },
        {
            label: '已完成',
            name: OrderType[4]
        },
        {
            label: '已关闭',
            name: OrderType[5]
        },
        {
            label: '已暂停',
            name: OrderType[6]
        },
        // {
        //     label: '即将完成',
        //     name: OrderType[7]
        // }
    ]

    index = 0

    pager = new RequestPaging()

    tabCount = {
        all_count: 0, //全部
        pay_count: 0, //待支付
        delivery_count: 0, //配送中
        receive_count: 0, //待配送
        finish_count: 0, //已完成
        close_count: 0, //已关闭
        suspend_count: 0 // 已暂停
    }

    form:any = {
        order_sn: '', //否	string	订单信息
        user_info: '', //否	string	用户信息
        goods_name: '', //否	string	商品名称
        contact_info: '', //否	string	收货人信息
        order_terminal: '', //否	int	订单来源;1-微信小程序;2-微信公众号;3-手机H5;4-PC;5-苹果app;6-安卓app;
        order_type_admin: '', //否	int	订单类型;0-普通订单;1-拼团订单;2-秒杀订单;3-砍价订单
        pay_way: '', //否	int	支付方式:1-余额支付;2-微信支付;3-支付宝支付;
        pay_status: '', //否	int	支付状态;0-待支付;1-已支付;
        delivery_type: '', //否	int	配送方式;1-快递发货;2-上门自提;3-同城配送
        time_type: '', //否	string	时间类型:create_time-下单时间;pay_time-支付时间
        start_time: '', //否	string	开始时间
        end_time: '', //否	string	结束时间
        deliveryman_id: '', // 配送员id
        community_id: '', // 小区id
    }
    order_status_list: any = [
        { name: '待付款', value: 0 },
        { name: '待配送', value: 1 },
        { name: '配送中', value: 2 },
        { name: '已完成', value: 3 },
        { name: '已关闭', value: 4 },
        { name: '已暂停', value: 5 },
    ]

    otherLists: any = {
        order_terminal_lists: [], //来源
        order_type_lists: [], //订单类型
        pay_way_lists: [], //余额支付
        pay_status_lists: [], //支付状态
        delivery_type_lists: [] //配送方式
    }
    // E Data

    // S Methods

    // 获取订单信息
    getOrderLists(page?: number) {
        if(!this.form.deliveryman_id) {
            this.$delete(this.form,'deliveryman_id')
        }
        page && (this.pager.page = page)
        if (this.form.start_time != '' || this.form.end_time != '') {
            if (this.form.time_type == '') {
                return this.$message.error('选择时间必须选择时间类型!')
            }
        }

        const status: any =
            this.activeName == 'all_count' ? '' : OrderType[this.activeName]
        this.pager
            .request({
                callback: apiOrderLists,
                params: {
                    order_status: status == '' ? '' : status - 1,
                    ...this.form
                }
            })
            .then(res => {
                this.tabCount = res?.extend
            })
    }

    // 重置搜索
    reset() {
        // sessionStorage.removeItem("")
        Object.keys(this.form).map(key => {
            this.$set(this.form, key, '')
        })
        this.getOrderLists()
    }
    // 获取配送员列表
    getDeliverymanList() {
        apiDeliverymanAll({}).then(res => {
            this.deliverymanList = res
        })
    }

    // 配送小区列表
    getCommunityList() {
        apiCommunityList({page_size: 10000}).then((res:any) => {
            this.communityList = res.lists
        })
    }

    // 获取订单其他 方式数据
    getOtherMethodList() {
        apiOtherLists().then((res: any) => {
            this.otherLists = res
        })
    }
    // E Methods

    // S  life cycle

    created() {
        if(JSON.stringify(this.$route.query)  != '{}') {
            console.log("this.$route.query", this.$route.query)
            if(this.$route.query.type == 'turnover') {
                let date = Number(new Date())
                this.form.time_type = 'pay_time'
                this.form.start_time = timeFormat(date) + ' 00:00:00'
                this.form.end_time = timeFormat(date)  + ' 23:59:59'
                this.form.pay_status = 1
            }
            // 临到期订单， 7天
            if(this.$route.query.type == 'expire') {
                let date = Number(new Date())
                this.form.time_type = 'expire_time'
                this.form.start_time = timeFormat(date) + ' 00:00:00'
               
                this.form.end_time = funDate(7) + ' ' + '00:00:00'
            }
           
            console.log(this.form.start_time, this.form.end_time)
        }
        // 获取订单信息
        this.getOrderLists()
        // 获取其他方式数据
        this.getOtherMethodList()
        // 获取配送员列表
        this.getDeliverymanList()
        // 获取小区列表
        this.getCommunityList()
    }

    // E life cycle
}
