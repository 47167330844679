






















































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
    apiOrderDeliveryInfo,
    apiOrderLogistics,
    apiOrderDelivery
} from '@/api/order/order'
@Component
export default class OrderLogistics extends Vue {
    @Prop({ default: '5vh' }) top!: string | number //弹窗的距离顶部位置
    @Prop({ default: '0' }) id!: string | number //订单ID
    @Prop({ default: true }) flag!: Boolean //是发货还是物流查询 true为发货 ，false为物流查询
    @Prop({ default: '' }) isShow!: string

    /** S Data **/
    visible = false //是否

    fullscreenLoading = false //加载方式

    // 物流订单信息
    orderData: any = {
        traces: {}
    }

    // 发货表单
    form: any = {
        send_type: 1, //	是	int	配送方式:1-快递配送;2-无需快递
        express_id: '', //	是(配送方式为1时必选)	int	订单id
        invoice_no: '', //	是(配送方式为1时必选)	int	订单id
        remark: '' //	否	varchar	发货备注
    }

    /** E Data **/

    /** S Method **/

    // 获取订单信息 flag 为 true的时候执行
    getOrderDeliveryInfo() {
        apiOrderDeliveryInfo({ id: this.id }).then(res => {
            this.orderData = res
            this.fullscreenLoading = false
        })
    }

    // 获取物流查询
    getOrderLogistics() {
        apiOrderLogistics({ id: this.id }).then(res => {
            this.orderData = res
            this.fullscreenLoading = false
        })
    }

    // 发货
    orderDelivery() {
        apiOrderDelivery({
            id: this.id,
            ...this.form
        }).then(res => {
            this.$emit('update', '')

            this.getOrderLogistics()
        })
    }

    // 商品信息的底部结算信息
    getSummaries(param: any) {
        const { columns, data } = param
        const sums: any = []
        columns.forEach((column: any, index: any) => {
            if (index === 0) {
                sums[2] = '总价'
                return
            }
            const values = data.map((item: any) =>
                Number(item[column.property])
            )
            if (!values.every((value: any) => isNaN(value))) {
                if (index == 4) {
                    return
                }
                sums[index] = values.reduce((prev: any, curr: any) => {
                    const value = Number(curr)
                    if (!isNaN(value)) {
                        return prev + curr
                    }
                    return prev
                }, 0)
                if (index !== 5 && index !== 3) {
                    sums[index] = '¥' + sums[index].toFixed(2)
                }
            }
        })

        return sums
    }

    // 点击取消
    handleEvent(type: 'cancel' | 'confirm') {
        if (type === 'cancel') {
            this.close()
        }
        if (type === 'confirm') {
            if (this.flag) {
                if (this.form.send_type == 1) {
                    if (this.form.express_id == '') {
                        return this.$message.error('请选择快递公司')
                    }
                    if (this.form.invoice_no == '') {
                        return this.$message.error('请填写快递单号')
                    }
                }
            }

            this.orderDelivery()
            this.close()
        }
    }

    // 打开弹窗
    onTrigger() {
        this.fullscreenLoading = true
        this.flag == true
            ? this.getOrderDeliveryInfo()
            : this.getOrderLogistics()
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false
    }

    // @Watch('isShow')
    // changeShow(value: any) {
    //
    // }
}
