

























































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { apiOrderPrint } from '@/api/application/print'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import OrderLogistics from '@/components/order/order-logistics.vue'
import {
    apiOrderRemarks,
    apiOrderCancel,
    apiOrderConfirm,
    apiCancelpart,
    apiOrderDel
} from '@/api/order/order'
import {
    apiReportDetailList, // 列表
    apiPauseDelivery, // 暂停配送
    apiStartDelivery // 开启配送
} from '@/api/delivery'
import { timeFormat } from '@/utils/util'
import { apiSelffetchVerification } from '@/api/application/selffetch'
@Component({
    components: {
        LsDialog,
        LsPagination,
        OrderLogistics
    }
})
export default class OrderPane extends Vue {
    $refs!: { form: any; paneTable: any }
    @Prop() value: any
    @Prop() pager!: any

    selectIds: any = []
    timeFormat = timeFormat

    dialogFormVisible = false // 退订弹窗isShow
    offVisible = false // 暂停配送或开启配送
    dateVisible = false // 更改配送周期的窗口

    remarks = '' //商家备注
    formLabelWidth = '120px' // 退订弹窗表单中label的宽
    weeks = ['周一', '周二', '周三', '周四', '周五', '周六', '周天'] // 配送方案
    orderId = ''
    order_amount = 0
    taskEditType = 0
    // 退订表单数据
    form: any = {
        refund_reason: [], // 退订原因
        stop_date: '', // 退订日期
        amount: '', // 退订金额
    }
    // 暂停或配送日期
    offForm: any = {
        date: '',
        order_id: ''
    }
    // 更改配送周期
    dateForm: any = {
        order_id: '', // 订单id
        order_goods_id: '', // 订单商品id
        delivery_cycle_type: '1', // 配送周期类型(1、自定义；2、隔日)
        delivery_cycle_detail: '1,2,3,4,5,6,7', // 配送周期具体详情（每周：1，2，3，4；隔日：0为不间隔，其他数字为间隔天数）
        every_num: '1', // 每次份数
        delivery_date: '', // 开始配送日期
    }

   
    // 退订弹窗表单验证
    rules = {
        amount: [
            {
                required: true,
                message: '请输入退订金额',
                trigger: ['blur', 'change']
            }
        ],
        refund_reason: [
            {
                required: true,
                message: '请选择退订原因',
                trigger: ['blur', 'change']
            }
        ],
        stop_date: [
            {
                required: true,
                message: '请选择暂停日期',
                trigger: ['blur', 'change']
            }
        ]
    }
    rules1 = {
        number: [
            {
                required: true,
                message: '请输入退订份数',
                trigger: ['blur', 'change']
            }
        ],
        reason: [
            {
                required: true,
                message: '请选择退订原因',
                trigger: ['blur', 'change']
            }
        ]
    }
    // 暂停或开启配送验证
    offFormRules = {
        date: [
            {
                required: true,
                message: '请选择时间',
                trigger: ['blur', 'change']
            }
        ]
    }
     // 更改配送日期验证
    dateFormRules = {
        delivery_date: [
            {
                required: true,
                message: '请选择时间',
                trigger: ['blur', 'change']
            }
        ]
    }

    private created() {
        // console.log('111', this.value)
    }

    /** S computed **/
    // get computedWeeks() {
    //     return this.weeks
    // }
    // set computedWeeks(val) {
    //     console.log('val', val)
    // }
    /** E computed **/

     // 更改配送规格
    dateDelivery(row:any) {
        console.log("2193128", row)
        this.dateForm.order_id = row.id
        this.dateForm.order_goods_id = ''
    }
    // 确认更改配送规格
    dateEdit() {

    }
     // 暂停或开启配送
     // 暂停配送
     suspendDelivery(row: any) {
        this.offVisible = true
        this.offForm.date = ""
        this.offForm.order_id = row.id
        this.taskEditType = 0

    }
    // 开启配送
    openDelivery(row: any) {
        this.offVisible = true
        this.offForm.date = ""
        this.offForm.order_id = row.id
        this.taskEditType = 1

     }
     
    taskEdit(row:any) {
        if(!this.offForm.date) {
            this.$message.error("请选择时间");
            return;
        }
        if(this.taskEditType == 1) {
        // 开启
            apiStartDelivery({
                ...this.offForm
            }).then(res => {
                this.closeDialog()
                this.getOrderLists()
                console.log("wqqwqwq", res)
            })
        }else {
        // 关闭
            apiPauseDelivery({
                ...this.offForm
            }).then(res => {
                this.closeDialog()
                this.getOrderLists()
                console.log("wqqwqwq", res)
            })
        }

    }

    computedWeeks(val: any) {
        let week = val.split(',')
        let newWeek: string[] = []
        week.map((i: any) => {
            newWeek.push(this.weeks[Number(i) - 1])
        })
        return newWeek.join(',')
    }
    // 获取订单信息
    getOrderLists() {
        console.log('value', this.value)
        ;(this.$parent as any).getOrderLists()
    }

    // 选择某条数据
    selectionChange(val: any[]) {
        this.selectIds = val.map(item => item.id)
    }

    // 全选
    selectAll() {
        ;(this.$refs.paneTable as any).toggleAllSelection()
    }

    // 去订单详情
    toOrder(id: any) {
        this.$router.push({
            path: '/order/order_detail',
            query: { id }
        })
    }

    toUser(id: any) {
        this.$router.push({
            path: '/user/user_details',
            query: { id: id }
        })
    }

    // 取消订单
    orderCancel(id: Number) {
        apiOrderCancel({ id: id }).then(res => {
            this.getOrderLists()
        })
    }

    // 删除订单
    orderDel(id: Number) {
        apiOrderDel({ id: id }).then(res => {
            this.getOrderLists()
        })
    }

    // 确认收货
    orderConfirm(id: Number) {
        apiOrderConfirm({ id: id }).then(res => {
            this.getOrderLists()
        })
    }

    async onPrintOrderFunc(row: any) {
        await apiOrderPrint({ id: row.id })
    }

    // 商家备注
    postOrderRemarks(id: any) {
        id = Array.isArray(id) == true ? id : this.selectIds
        apiOrderRemarks({
            id: id,
            order_remarks: this.remarks
        }).then(res => {
            this.remarks = ''
            this.getOrderLists()
        })
    }

    // 订单核销
    onSelffetchOrderVerification(id: number) {
        apiSelffetchVerification({
            id
        }).then(() => {
            // 订单核销成功就请求新列表
            this.getOrderLists()
        })
    }
    // 打开退订弹窗
    onUnsubscribe(row: any) {
        this.orderId = row.id
        this.order_amount = Number(row.order_amount)
        this.dialogFormVisible = true
    }
    // 打开商家备注弹窗
    openDialog() {
        if(this.selectIds.length == 0) {
            this.$message.error("请先勾选订单");
            return;
        }
    }
    // 确认退订
    confirmTk() {
        this.$refs.form.validate((valid: boolean) => {
            if (valid) {
                let params = {
                    refund_reason: this.form.refund_reason.join(','),
                    stop_date: this.form.stop_date,
                    amount: this.form.amount,
                    id: this.orderId
                }
                if (this.order_amount < this.form.amount) {
                    this.$message.error('退款金额不能大于订单实付金额')
                    return
                }
                console.log('form', this.form)
                apiCancelpart(params)
                    .then(res => {
                        this.getOrderLists()
                        this.dialogFormVisible = false
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                return false
            }
        })
    }
    // 关闭退订弹窗
    closeDialog() {
        this.form = {
            reason: [], // 退订原因
            amount: '', // 退订金额
            number: ''
        }
        this.offVisible = false
        this.dialogFormVisible = false
        
    }
}
